.playlistDetail {
    display: flex;
    justify-content: space-evenly;
    background-image: linear-gradient(to right bottom, rgb(113, 40, 42), rgb(0, 0, 0)), linear-gradient(transparent, rgb(0, 0, 0) 70%);
    padding: 20px;
    height: 100%;
}

.tracks {
    width: 70%;
}

.track {
    display: flex;
    padding: 10px;
    color: #fff;
}

.track:focus {
    outline-width: 0;
}

.track:focus > .track__item > .track__name {
    color: #1ed760;
}

.track:hover {
    background-color: rgba(0, 0, 0, .3);
}

.track__icon {
    background: url("play-icon.svg");
    width: 16px;
    height: 18px;
    background-size: cover;
}

.track__name {
    font-size: 16px;
    line-height: 22px;
    letter-spacing: .015em;
}

.track__item {
    padding-left: 10px;
}

.track__artist {
    font-size: 14px;
    opacity: .6;
    -webkit-transition: opacity .2s linear;
    transition: opacity .2s linear;
    display: flex;
}

.album {
    display: flex;
    flex-direction: column;
    font-size: 28px;
    line-height: 36px;
    letter-spacing: -.005em;
    font-weight: bold;
    color: #fff;
    text-transform: none;
    align-items: center;
}

.album__cover {
    width: 263px;
    height: 263px;
    padding: 10px;
    max-height: 100%;
    max-width: 100%;
    object-fit: cover;
}

.album__count {
    font-size: 11px;
    line-height: 16px;
    text-transform: uppercase;
    letter-spacing: .16em;
    color: #fff;
    opacity: .6;
    margin-top: 24px;
}

.album_details {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 15px 0;
}


@media only screen and (max-width: 1200px) {
    .playlistDetail {
        flex-direction: column;
        justify-content: flex-start;
    }
    .album {
        flex-direction: row;
        align-items: flex-start;
        padding-bottom: 35px;
        border-bottom: 1px solid hsla(0,0%,100%,.1);
    }

    .album__cover {
        width: 150px;
        height: 150px;
        padding: 10px;
        max-height: 100%;
        max-width: 100%;
        object-fit: cover;
        margin-right: 40px;
    }
    .tracks {
        margin-top: 35px;
        width: 100%;
    }
}


@media only screen and (max-width: 600px) {
    .album_details {
        font-size: 18px;
    }
    .album__cover {
        width: 100px;
        height: 100px;
        padding: 10px;
        max-height: 100%;
        max-width: 100%;
        object-fit: cover;
        margin-right: 20px;
    }
}

