.playlistTitle__item {
    display: flex;
    flex-direction: column;
    margin: 10px 24px;
    user-focus: none;
}

.playlistTitle__name {
    color: #b3b3b3;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: .015em;
    text-transform: none;
    width: 230px;
}

.playlistTitle__type {
    font-size: 11px;
    line-height: 16px;
    text-transform: uppercase;
    letter-spacing: .16em;
    color: #fff;
    opacity: .6;
    padding-top: 1px;
    display: block;
}

.playlistTitle:focus {
    content: "";
    display: block;
    bottom: 5px;
    width: 4px;
    background-color: #1ed760;
    outline-width: 0;
    pointer-events: auto;
}

.playlistTitle:focus > .playlistTitle__item > .playlistTitle__name {
    outline-width: 0;
    color: white;
}


@media only screen and (max-width: 720px) {
    .playlistTitle__item {
        display: none;
    }
}
