.menu {
}

.menu__item {
    display: flex;
    align-items: center;
    width: 200px;
    font-size: 14px;
    margin: 24px;
    font-weight: bold;
    color: #b3b3b3;
}

.menu__icon {
    background-image: url("playlisticon.svg");
    background-size: cover;
    width: 24px;
    height: 24px;
}

.menu__description {
    padding-left: 18px;
}

.menu:focus {
    content: "";
    display: block;
    bottom: 5px;
    width: 4px;
    background-color: #1ed760;
    outline-width: 0;
    color: white;

}

.menu:focus > .menu__item > .menu__icon {
    background-image: url("playlisticon-active.svg");
    background-size: cover;
    width: 24px;
    height: 24px;
}

.menu:focus > .menu__item > .menu__description {
    color: #fff;
}

@media only screen and (max-width: 720px) {
    .menu__description {
        display: none;
    }

    .menu__item {
        justify-content: center;
        width: 34px;
    }
}

