.libraryList {
    display: flex;
    flex-wrap: wrap;
    padding: 30px;
}

.library__name {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 10px;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
}

.library__cover{
    width: 170px;
    height: 170px;
    object-fit: cover;
    padding-bottom: 10px;
}

.library__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 20px 0 0;
}

.library_menu {
    list-style: none;
}

.library_menu--item {
    opacity: 1;
    font-size: 12px;
    font-weight: bold;
    line-height: 16px;
    text-transform: uppercase;
    letter-spacing: .16em;
    color: #fff;
    position: relative;
    border: 0;
    display: inline-block;
    margin: 10px;
    padding: 5px 10px;
    text-decoration: none;
    -webkit-transition: opacity .2s linear;
    transition: opacity .2s linear;
}

.library_menu--item:before {
    content: "";
    height: 2px;
    background-color: #1db954;
    width: 30px;
    position: absolute;
    display: block;
    left: 50%;
    bottom: 0;
    margin-left: -15px
}

.button {
    font-size: 11px;
    text-transform: uppercase;
    letter-spacing: .16em;
    -webkit-transition: none 33ms cubic-bezier(.3,0,.7,1);
    transition: none 33ms cubic-bezier(.3,0,.7,1);
    transition-property: all;
    color: #fff;
    background-color: #1db954;
    display: inline-block;
    text-align: center;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    background-image: none;
    border: 0;
    border-radius: 500px;
    min-width: 130px;
    white-space: normal;
    will-change: transform;
    line-height: 1.6;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    padding: 7px 22px;
    margin: 0 10px;
    outline-width: 0;
    cursor: pointer;
}

@media only screen and (max-width: 1200px) {
    .library__header {
        flex-direction: column;
    }
}

@media only screen and (max-width: 720px) {
    .button {
        margin: 10px    ;
    }
}