.sidebar {
    display: flex;
    flex-direction: column;
    width: 230px;
    background-color: black;
    color: white;
    min-width: 200px;
}

@media only screen and (max-width: 720px) {
    .sidebar {
        width: 80px;
        min-width: 80px;
        text-align: center;
    }
}