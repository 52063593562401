.logo {
    background: url(logo.svg) no-repeat;
    background-size: contain;
    width: 131px;
    height: 55px;
    margin: 10px 24px;
}

@media only screen and (max-width: 720px) {
    .logo {
        background: url(logo-small.png) no-repeat;
        background-size: contain;
        width: 32px;
        height: 32px;
        margin: 24px;
    }
}