body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.menu {
}

.menu__item {
    display: flex;
    align-items: center;
    width: 200px;
    font-size: 14px;
    margin: 24px;
    font-weight: bold;
    color: #b3b3b3;
}

.menu__icon {
    background-image: url(/static/media/playlisticon.0db5e09e.svg);
    background-size: cover;
    width: 24px;
    height: 24px;
}

.menu__description {
    padding-left: 18px;
}

.menu:focus {
    content: "";
    display: block;
    bottom: 5px;
    width: 4px;
    background-color: #1ed760;
    outline-width: 0;
    color: white;

}

.menu:focus > .menu__item > .menu__icon {
    background-image: url(/static/media/playlisticon-active.2b2fbf55.svg);
    background-size: cover;
    width: 24px;
    height: 24px;
}

.menu:focus > .menu__item > .menu__description {
    color: #fff;
}

@media only screen and (max-width: 720px) {
    .menu__description {
        display: none;
    }

    .menu__item {
        justify-content: center;
        width: 34px;
    }
}


.playlistTitle__item {
    display: flex;
    flex-direction: column;
    margin: 10px 24px;
    user-focus: none;
}

.playlistTitle__name {
    color: #b3b3b3;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: .015em;
    text-transform: none;
    width: 230px;
}

.playlistTitle__type {
    font-size: 11px;
    line-height: 16px;
    text-transform: uppercase;
    letter-spacing: .16em;
    color: #fff;
    opacity: .6;
    padding-top: 1px;
    display: block;
}

.playlistTitle:focus {
    content: "";
    display: block;
    bottom: 5px;
    width: 4px;
    background-color: #1ed760;
    outline-width: 0;
    pointer-events: auto;
}

.playlistTitle:focus > .playlistTitle__item > .playlistTitle__name {
    outline-width: 0;
    color: white;
}


@media only screen and (max-width: 720px) {
    .playlistTitle__item {
        display: none;
    }
}

.recentlyPlayed {
    margin: 10px 24px;
}


@media only screen and (max-width: 720px) {
    .recentlyPlayed {
        display: none;
    }
}
.sidebar {
    display: flex;
    flex-direction: column;
    width: 230px;
    background-color: black;
    color: white;
    min-width: 200px;
}

@media only screen and (max-width: 720px) {
    .sidebar {
        width: 80px;
        min-width: 80px;
        text-align: center;
    }
}
.logo {
    background: url(/static/media/logo.ef96e830.svg) no-repeat;
    background-size: contain;
    width: 131px;
    height: 55px;
    margin: 10px 24px;
}

@media only screen and (max-width: 720px) {
    .logo {
        background: url(/static/media/logo-small.d0d7f784.png) no-repeat;
        background-size: contain;
        width: 32px;
        height: 32px;
        margin: 24px;
    }
}
.playlistDetail {
    display: flex;
    justify-content: space-evenly;
    background-image: -webkit-linear-gradient(left top, rgb(113, 40, 42), rgb(0, 0, 0)), -webkit-linear-gradient(transparent, rgb(0, 0, 0) 70%);
    background-image: linear-gradient(to right bottom, rgb(113, 40, 42), rgb(0, 0, 0)), linear-gradient(transparent, rgb(0, 0, 0) 70%);
    padding: 20px;
    height: 100%;
}

.tracks {
    width: 70%;
}

.track {
    display: flex;
    padding: 10px;
    color: #fff;
}

.track:focus {
    outline-width: 0;
}

.track:focus > .track__item > .track__name {
    color: #1ed760;
}

.track:hover {
    background-color: rgba(0, 0, 0, .3);
}

.track__icon {
    background: url(/static/media/play-icon.f607da49.svg);
    width: 16px;
    height: 18px;
    background-size: cover;
}

.track__name {
    font-size: 16px;
    line-height: 22px;
    letter-spacing: .015em;
}

.track__item {
    padding-left: 10px;
}

.track__artist {
    font-size: 14px;
    opacity: .6;
    -webkit-transition: opacity .2s linear;
    transition: opacity .2s linear;
    display: flex;
}

.album {
    display: flex;
    flex-direction: column;
    font-size: 28px;
    line-height: 36px;
    letter-spacing: -.005em;
    font-weight: bold;
    color: #fff;
    text-transform: none;
    align-items: center;
}

.album__cover {
    width: 263px;
    height: 263px;
    padding: 10px;
    max-height: 100%;
    max-width: 100%;
    object-fit: cover;
}

.album__count {
    font-size: 11px;
    line-height: 16px;
    text-transform: uppercase;
    letter-spacing: .16em;
    color: #fff;
    opacity: .6;
    margin-top: 24px;
}

.album_details {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 15px 0;
}


@media only screen and (max-width: 1200px) {
    .playlistDetail {
        flex-direction: column;
        justify-content: flex-start;
    }
    .album {
        flex-direction: row;
        align-items: flex-start;
        padding-bottom: 35px;
        border-bottom: 1px solid hsla(0,0%,100%,.1);
    }

    .album__cover {
        width: 150px;
        height: 150px;
        padding: 10px;
        max-height: 100%;
        max-width: 100%;
        object-fit: cover;
        margin-right: 40px;
    }
    .tracks {
        margin-top: 35px;
        width: 100%;
    }
}


@media only screen and (max-width: 600px) {
    .album_details {
        font-size: 18px;
    }
    .album__cover {
        width: 100px;
        height: 100px;
        padding: 10px;
        max-height: 100%;
        max-width: 100%;
        object-fit: cover;
        margin-right: 20px;
    }
}


.main {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
  color: white;
  flex:3 1;
}

.libraryList {
    display: flex;
    flex-wrap: wrap;
    padding: 30px;
}

.library__name {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 10px;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
}

.library__cover{
    width: 170px;
    height: 170px;
    object-fit: cover;
    padding-bottom: 10px;
}

.library__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 20px 0 0;
}

.library_menu {
    list-style: none;
}

.library_menu--item {
    opacity: 1;
    font-size: 12px;
    font-weight: bold;
    line-height: 16px;
    text-transform: uppercase;
    letter-spacing: .16em;
    color: #fff;
    position: relative;
    border: 0;
    display: inline-block;
    margin: 10px;
    padding: 5px 10px;
    text-decoration: none;
    -webkit-transition: opacity .2s linear;
    transition: opacity .2s linear;
}

.library_menu--item:before {
    content: "";
    height: 2px;
    background-color: #1db954;
    width: 30px;
    position: absolute;
    display: block;
    left: 50%;
    bottom: 0;
    margin-left: -15px
}

.button {
    font-size: 11px;
    text-transform: uppercase;
    letter-spacing: .16em;
    -webkit-transition: none 33ms cubic-bezier(.3,0,.7,1);
    transition: none 33ms cubic-bezier(.3,0,.7,1);
    -webkit-transition-property: all;
    transition-property: all;
    color: #fff;
    background-color: #1db954;
    display: inline-block;
    text-align: center;
    touch-action: manipulation;
    background-image: none;
    border: 0;
    border-radius: 500px;
    min-width: 130px;
    white-space: normal;
    will-change: transform;
    line-height: 1.6;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    padding: 7px 22px;
    margin: 0 10px;
    outline-width: 0;
    cursor: pointer;
}

@media only screen and (max-width: 1200px) {
    .library__header {
        flex-direction: column;
    }
}

@media only screen and (max-width: 720px) {
    .button {
        margin: 10px    ;
    }
}
.app {
    display: flex;
    font-family: Helvetica Neue,Helvetica,Arial,Hiragino Kaku Gothic Pro,Meiryo,MS Gothic,sans-serif;
}
.pointer {
    cursor: pointer;
}
